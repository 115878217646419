import React from 'react';
import '../App.css';
import FormPDF from './FormPDF';
import Header from './Header';
import Footer from './Footer';
import HowTo from './HowTo';

function Principal() {

  return (
    <div className="App">
      <Header/>
      <FormPDF/>
      <HowTo/>
      <Footer/>
    </div>
  );
}

export default Principal;