import React, { useState, useRef } from 'react';
import { PDFDocument } from 'pdf-lib';
import "../styles/FormPDF.css";
import Progress from './Progress';
import SelectPropertiesPDF from './SelectPropertiesPDF';
import PDFPreview from './PDFPreview';
import axios from 'axios';
import 'react-tippy/dist/tippy.css'; // Import Tippy.js styles
import 'tippy.js/animations/scale.css'; // Import Tippy.js animation styles
import { Tooltip } from 'react-tippy';


const API_BASE_URL = 'https://onlysplitpdf-75429c6816ab.herokuapp.com';

function FormPDF(props) {

  const [splitPdfs, setSplitPdfs] = useState();
  const fileInputRef = useRef(null);



  const handlePDFProcessing = async (ranges) => {
    const file = fileInputRef.current.files[0];
    try {

      const response = await axios.post(`${API_BASE_URL}/api/process-pdf`, {
        pdf_file: file,
        name: file.name,
        ranges: ranges
      },
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          responseType: 'blob',
        });
      console.log(response.data);
      setSplitPdfs(response.data);
    } catch (error) {
      console.error('Error processing PDF:', error);
    }
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const [fileName, setFileName] = useState("");

  const [controlButtonReset, setControlButtonReset] = useState(false);

  const [controlSplitting, setControlSplitting] = useState(true);

  const [pages, setPages] = useState([]);

  const [pdf, setPDF] = useState();

  const [pdfPreviewList, setPdfPreviewList] = useState([]);

  const [divZone, setDivZone] = useState(false);


  function resetDropzone() {
    setSelectedFile(null);
    setFileName("");
    hideControlButtonReset();
    setControlSplitting(true);
  };

  function hideControlButtonReset() {
    setControlButtonReset(false);
  };

  const createPDFPreview = (key, previewFirst, previewSecond) => {
    setPdfPreviewList((prevList) => {
      const preview = {
        key: key,
        id: key,
        pdf: pdf,
        previewFirst: previewFirst,
        previewSecond: previewSecond,
      };
      return [...prevList, preview];
    });
  };

  const updatePDFPreviewFromValue = (id, fromValue) => {
    const new_pdfpreview_list = pdfPreviewList.map((preview) => {
      if (preview.key === id) {
        return { ...preview, previewFirst: parseInt(fromValue) - 1 };
      }
      return preview;
    });
    setPdfPreviewList(new_pdfpreview_list);
  };

  const updatePDFPreviewToValue = (id, toValue) => {
    const new_pdfpreview_list = pdfPreviewList.map((preview) => {
      if (preview.key === id) {
        return { ...preview, previewSecond: parseInt(toValue) - 1 };
      }
      return preview;
    });
    setPdfPreviewList(new_pdfpreview_list);
  }

  const updatePDFPreviewBothValues = (id, fromValue, toValue) => {
    const new_pdfpreview_list = pdfPreviewList.map((preview) => {
      if (preview.key === id) {
        return { ...preview, previewFirst: parseInt(fromValue) - 1, previewSecond: parseInt(toValue) - 1 };
      }
      return preview;
    });
    setPdfPreviewList(new_pdfpreview_list);
  }

  const fileUploaded = async (event) => {

    if (event.target.files[0].type === 'application/pdf') {
      setControlSplitting(false);
      const file = event.target.files[0];
      setPDF(file);
      const pdfBuffer = await file.arrayBuffer();
      const pdfDoc = await PDFDocument.load(pdfBuffer);
      setPages(pdfDoc.getPages());
      setDivZone(true);
    }
  }

  const [fileNoDropped, setFileNoDropped] = useState(true);

  const handleDivClick = async () => {
    document.getElementById("fileInput").click();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const dropzone = document.getElementById('dropzone');
    dropzone.classList.remove('dragging');
    const fileInput = document.getElementById("fileInput");

    if (e.dataTransfer.files[0].type === 'application/pdf') {
      const fileList = new DataTransfer();
      fileList.items.add(e.dataTransfer.files[0]);
      fileInput.files = fileList.files;
      const changeEvent = new Event('change', { bubbles: true });
      fileInput.dispatchEvent(changeEvent);
      setFileNoDropped(false);
    }
  }



  const handleDragOver = (e) => {
    e.preventDefault();
    const dropzone = document.getElementById('dropzone');
    dropzone.classList.add('dragging');
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    const dropzone = document.getElementById('dropzone');
    dropzone.classList.remove('dragging');
  };

  const [splitting, setSplitting] = useState(false);
  const [donwloadControl, setDonwloadControl] = useState(false);

  const splitClick = async (divisionsList) => {
    setDivZone(false);
    setSplitting(true);
    var ranges = "";
    divisionsList.map((division) => {
      if (division.fromValue === division.toValue) {
        ranges += division.fromValue + ",";
      } else {
        ranges += division.fromValue + "-" + division.toValue + ",";
      }
    })
    ranges = ranges.slice(0, -1);
    await handlePDFProcessing(ranges).then(() => {
      setSplitting(false);
      setDonwloadControl(true);
    });
  }

  const handleReload = () => {
    window.location.reload();
  };

  const clickDownload = () => {
    document.getElementById("download_tag").click();
  };

  const deletAllPDFPreview = () => {
    setPdfPreviewList(pdfPreviewList.filter((preview) => null));
  }

  const deletePDFPreview = (id) => {
    const new_pdfpreview_list = pdfPreviewList.filter((preview) => preview.key !== id);
    setPdfPreviewList(new_pdfpreview_list);
  };

  const [pdfBase64, setPdfBase64] = useState([]);

  const savePdfBase64 = (base64, page) => {
    setPdfBase64((prevList) => {
      const aux_base64 = {
        base64: base64,
        page: page
      }
      return [...prevList, aux_base64];
    });
  };

  const getPdfBase64 = (page) => {
    const aux_base64 = pdfBase64.filter((item) => item.page === page);
    try {
      return aux_base64[0].base64;
    } catch (error) {
      return null;
    }
  }

  const onClickShareLink = (link) => {
    window.open(link, '_blank');
  }

  return (
    <div>
      <div id="dropzone" className="dropzone" onDrop={fileNoDropped ? handleDrop : null} onDragOver={fileNoDropped ? handleDragOver : null} onDragLeave={fileNoDropped ? handleDragLeave : null}>
        <div className="ad" id="ad_lateral_izquierda">
        </div>

        {donwloadControl ?
          <div className="download_pdf">
            <h2>There is your PDF's</h2>
            <button className="button_select_file" onClick={clickDownload}>Download</button>
            <div className='share-buttons'>
              <Tooltip title="Share on Facebook" position="bottom" animation="scale" arrow>
                <button data-tippy-arrow="true" onClick={() => onClickShareLink("https://www.facebook.com/sharer/sharer.php?u=https://onlysplitpdf.com")} target="_blank" className="facebook"></button>
              </Tooltip>
              <Tooltip title="Share on Twitter" position="bottom" animation="scale" arrow>
                <button onClick={() => onClickShareLink("https://twitter.com/intent/tweet?url=https://onlysplitpdf.com&text=Descripción")} target="_blank" className="twitter"></button>
              </Tooltip>
              <Tooltip title="Share on Linkedin" position="bottom" animation="scale" arrow>
                <button onClick={() => onClickShareLink("https://www.linkedin.com/shareArticle?url=https://onlysplitpdf.com")} target="_blank" className="linkedin"></button>
              </Tooltip>
            </div>
            <a
              id="download_tag"
              className="download_tag"
              href={window.URL.createObjectURL(new Blob([splitPdfs], { type: "application/zip" }))}
              download={"only_split_pdf.zip"}
            />
            <a className="reload_tag" href="#" onClick={handleReload}>Split more PDF's</a>
          </div> : null
        }

        {splitting && <Progress id="progress" />}
        {controlSplitting &&
          <div className='set_button_select'>
            <h1 className='select_pdf_h1'>Drag your PDF's or click to select</h1>
            <h2 className='select_pdf_h2'>Divide a page or an entire set to simplify the process of splitting in individual PDF files. Enhance the organization of your PDF documents quickly and easily.</h2>
            <div className="button_and_links">
              <button className="button_select_file" onClick={handleDivClick}>Select PDF</button>
              <div className='share-buttons'>
                <Tooltip title="Share on Facebook" position="bottom" animation="scale" arrow>
                  <button data-tippy-arrow="true" onClick={() => onClickShareLink("https://www.facebook.com/sharer/sharer.php?u=https://onlysplitpdf.com")} target="_blank" className="facebook"></button>
                </Tooltip>
                <Tooltip title="Share on Twitter" position="bottom" animation="scale" arrow>
                  <button onClick={() => onClickShareLink("https://twitter.com/intent/tweet?url=https://onlysplitpdf.com&text=Descripción")} target="_blank" className="twitter"></button>
                </Tooltip>
                <Tooltip title="Share on Linkedin" position="bottom" animation="scale" arrow>
                  <button onClick={() => onClickShareLink("https://www.linkedin.com/shareArticle?url=https://onlysplitpdf.com")} target="_blank" className="linkedin"></button>
                </Tooltip>
              </div>
            </div>
          </div>
        }
        <input
          id="fileInput"
          type="file"
          accept=".pdf"
          ref={fileInputRef}
          onChange={fileUploaded}
          hidden
        />

        {divZone ?
          <div className='pdf_preview_list'>
            {pdfPreviewList.map((newPDFPreview) =>
              <PDFPreview
                key={newPDFPreview.key}
                id={newPDFPreview.key}
                pdf={newPDFPreview.pdf}
                previewFirst={newPDFPreview.previewFirst}
                previewSecond={newPDFPreview.previewSecond}
                savePdfBase64={savePdfBase64}
                pdfBase64={pdfBase64}
                getPdfBase64={getPdfBase64}
              />
            )}
          </div> : null}

        {selectedFile && (
          <div>
            <h2>Archivo PDF con páginas impares:</h2>
            <a href={selectedFile} download={fileName}>
              Descargar PDF con páginas impares
            </a>
            {controlButtonReset && <button id="control_button" onClick={resetDropzone} />}
          </div>
        )}

        {divZone ?
          <div className='container_select_properties_pdf'>
            <SelectPropertiesPDF pdf={pdf} pages={pages} createPDFPreview={createPDFPreview} updatePDFPreviewFromValue={updatePDFPreviewFromValue} updatePDFPreviewToValue={updatePDFPreviewToValue} updatePDFPreviewBothValues={updatePDFPreviewBothValues} splitClick={splitClick} deletAllPDFPreview={deletAllPDFPreview} deletePDFPreview={deletePDFPreview} />
          </div>
          : <div className="ad"></div>}
      </div>
      <div className="ad_banner" id="ad_banner_dropzone">
      </div>
    </div>
  );
}

export default FormPDF;