import React from "react";
import "../styles/Progress.css";

function Progress(props){
    return(
    <div className="upload-container">
        <div className="circle"></div>
        <div className="progress-text">Splitting...</div>
    </div>
    )
}

export default Progress;