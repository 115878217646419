import React from "react";
import "../styles/Footer.css";
import { Link } from 'react-router-dom';

function Footer(){
    const year = new Date().getFullYear();
    return(
        <div className="footer_div">
            <Link className="footer_text" to="/">© OnlyPDF {year} ® - Best PDF Tools</Link>
            <div>
                <Link className="footer_links" to="/legal-advertising">Legal Advertising</Link>
                <Link className="footer_links" to="/privacy">Privacy and Cookies Policy</Link>
            </div>
        </div>
    );
}

export default Footer;