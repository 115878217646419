import React from "react";
import "../styles/Blog.css";
import Footer from "./Footer";
import Header from "./Header";
import { Link } from 'react-router-dom';

function Blog() {


    return (
        <div className="blog_principal_div">
            <Header />
            <h1 className="h1_blog">Enjoy Our Blog</h1>
            <ul className="ul_blog">
                <li className="li_blog">
                    <Link className="blog_link_prin" to="/blog1">Simplify Your PDF Management with Only Split PDF</Link>
                </li>
                <li className="li_blog">
                    <Link className="blog_link_prin" to="/blog2">The Profound Benefits of Embracing PDFs in Your Daily Workflow</Link>
                </li>
                <li className="li_blog">
                    <Link className="blog_link_prin" to="/blog3">The Unfolding Saga of PDFs: Evolution, Impact, and Enduring Significance</Link>
                </li>
                
            </ul>
            <Footer />
        </div>
    );
}

export default Blog;