import "../styles/Header.css";
import { Link } from 'react-router-dom';
import logo from "../img/Logo OnlyPDF.svg";
import React from "react";

const handleImgClick = () => {
  window.location.href = "/";
}

function Header (){

  return(
    <div className="header">
      <img className="logo" src={logo} alt="logo" onClick={handleImgClick}></img>
      <Link className="title" to="/">OnlySplit</Link>
      
      <a className="only_pdf_links" id="only_merge_pdf" href="https://onlymergepdf.com/">MERGE PDF</a>
      {/* <a className="only_pdf_links" id="only_split_pd_blog" href="https://onlysplitpdf.com/blog">BLOG</a> */}
      <Link className="only_pdf_links" id="only_split_pd_blog" to="/blog">BLOG</Link>
    </div>
  );
}

export default Header;