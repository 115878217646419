import React from "react";
import "../styles/HowTo.css";
import howTo1 from "../img/HowTo1.png";
import howTo2 from "../img/HowTo2.png";
import howTo3 from "../img/HowTo3.png";
import howTo4 from "../img/HowTo4.png";
import howTo5 from "../img/HowTo5.png";

function HowTo() {

    return (

        <div className="howto_div_container">
            <h1>How to use OnlySplitPDF</h1>
            <div className="howto_div_odd">
                <img className="howto_img" src={howTo1} alt="How to Select Or Drag PDF's for splitting" />
                <div className="howto_text_div">
                    <h2>1. Select the PDF file you want to convert</h2>
                    <p>Click on the &ldquo;Select PDF&ldquo; button to select the PDF file you want to convert. You can also drag and drop the PDF file you want to convert into the box.</p>
                </div>
            </div>

            <div className="howto_div_even">
                <div className="howto_text_div">
                    <h2>2. Custom Ranges</h2>
                    <p>Add more custom ranges, change the pages you want, delete it or use the option Fixed Ranges.</p>
                </div>
                <img id="img_custom_ranges" className="howto_img" src={howTo2} alt="Use Custom Ranges for PDF's splitting" />
            </div>

            <div className="howto_div_odd">
                <img id="img_fixed_ranges" className="howto_img" src={howTo3} alt="Use Fixed Ranges for PDF's splitting" />
                <div className="howto_text_div">
                    <h2>3. Fixed Ranges</h2>
                    <p>You have more options, you can separate in fixed ranges of 1,2,3,... or separate in odd or even pages, and use Edit Your ranges to set manually.</p>
                </div>
            </div>

            <div className="howto_div_even">
                <div className="howto_text_div">
                    <h2>4. Edit Your Ranges</h2>
                    <p>Edit the ranges using the following pattern: &ldquo;1,2-3,4,5-6,...&ldquo;. To create a range, separate the numbers with a hyphen. If you want a page to stand alone, write it by itself, all surrounded by commas. You will see it updating as you go, so don&apos;t worry.</p>
                </div>
                <img id="img_edit_ranges" className="howto_img" src={howTo4} alt="Edit Ranges for Split PDF's" />
            </div>

            <div className="howto_div_odd">
                <img className="howto_img" src={howTo5} alt="Donwload PDF's splitted" />
                <div className="howto_text_div">
                    <h2>5. Download PDF&apos;s splitted</h2>
                    <p>Click on Download and Enjoy your PDF splitted!</p>
                </div>
            </div>
        </div >
    )
}

export default HowTo;