import './App.css';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Principal from './components/Principal';
import Legal from './components/Legal';
import PrivCook from './components/PrivCook';
import Blog from './components/Blog';
import Blog1 from './components/Blog1';
import Blog2 from './components/Blog2';
import Blog3 from './components/Blog3';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Principal />} />
        <Route path="/legal-advertising" element={<Legal />} />
        <Route path="/privacy" element={<PrivCook />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog1" element={<Blog1 />} />
        <Route path="/blog2" element={<Blog2 />} />
        <Route path="/blog3" element={<Blog3 />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
